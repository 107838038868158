// import axios from 'axios';
import axios from './axiosInstance';
import config from '../_config/config';
import {
  getItemFromStorage,
  storeItem,
  clearSessionStorage,
  setTokens,
  getUser,
  setUser,
  clearLocalStorage
} from '../helpers/localStorage';

// axios.defaults.withCredentials = true;

const getCsrf = async () => {
  let csrf = getItemFromStorage('csrf_token');

  if (!csrf) {
    const csrfLogin = await axios.post(config.api.api_url + config.api.auth.csrf_login, {
      request: true
    });
    csrf = csrfLogin.data.csrf_token;
    storeItem('csrf_token', csrf);
  }
  return csrf;
};

export const login = async (email, password, remember = null) => {
  const csrfToken = await getCsrf();
  let res = {};
  try {
    const user = await axios.post(config.api.api_url + config.api.auth.loginV2, {
      csrf_token: csrfToken,
      email,
      password,
      remember
    });
    user.data.logged_in = true;
    storeItem('user', user.data);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.response.data);
    res = error.response.data;
  }
  return res;
};

export const logout = async () => {
  try {
    await axios.delete(config.api.api_url + config.api.auth.logout);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  } finally {
    clearLocalStorage();
    clearSessionStorage();
  }
};

export const fetchUserData = () => {
  let user = getUserData();
  if (user.logged_in) return true;
  try {
    axios.get(config.api.api_url + config.api.auth.auth_test).then(resp => {
      storeItem('user', resp.data);
    });
  } catch (error) {
    if (error.response && error.response.data) {
      storeItem('user', error.response.data);
    }
  }
  user = getUserData();
  return user.logged_in;
};

export const getUserData = () => {
  let user = getUser();
  if (!user) {
    // possible fix, the issue is that sometimes code run this function even if we out of page,
    // I guess it's a last render before redirect to the login page.
    // testing...
    user = { logged_in: false, roles: [] };
    storeItem('user', user);
  }
  return user;
};

export const resetPassword = async (token, data) => {
  let result;
  try {
    await axios.post(config.api.api_url + config.api.auth.reset_password(token), data);
    result = {
      msg: 'success',
      success: true
    };
  } catch (e) {
    result = {
      msg: 'Server Error',
      success: false
    };
    if (e.response && e.response.data.errors) {
      result.msg = e.response.data.errors;
    }
  }
  return result;
};

export const forgotPassword = async email => {
  let result;
  try {
    await axios.post(config.api.api_url + config.api.auth.forgot_password, { email });
    result = {
      msg: '',
      success: true
    };
  } catch (e) {
    // eslint-disable-next-line
    console.log(e.response);
    result = {
      msg: 'Server error',
      success: false
    };
    if (e.response && e.response.data.errors) {
      result.msg = e.response.data.errors;
    }
  }
  return result;
};

// V2 login

export const getMe = async () => {
  let result = false;
  try {
    const response = await axios.get(config.api.api_url + config.api.auth.me);
    setUser(response.data);
    result = { result: true, user: response.data };
  } catch (e) {
    clearLocalStorage();
    result = { result: false };
  }
  return result;
};

export const loginV2 = async (email, password) => {
  let res = {};
  try {
    const response = await axios.post(config.api.api_url + config.api.auth.loginV2, {
      email,
      password
    });
    setTokens(response.data);
    res.use_mfa = response.data.use_mfa;
    res.success = true;
  } catch (error) {
    // eslint-disable-next-line
    console.dir(error);
    res = error.response.data;
    res.success = false;
  }
  return res;
};

export const reSendCode = async (email, password) => {
  let res = {};
  try {
    await axios.post(config.api.api_url + config.api.auth.resend_code, {
      email,
      password
    });
    res.success = true;
  } catch (error) {
    // eslint-disable-next-line
    console.dir(error);
    res = error.response.data;
    res.success = false;
  }
  return res;
};

export const sendCode = async (email, password, code) => {
  const res = {};
  try {
    const response = await axios.post(config.api.api_url + config.api.auth.send_code, {
      email,
      password,
      code
    });
    setTokens(response.data);
    res.success = true;
  } catch (error) {
    // eslint-disable-next-line
    console.dir(error);
    res.data = error.response.data;
    res.success = false;
  }
  return res;
};

export const getMode = group => {
  const {
    current_user: { id }
  } = getUserData();
  if (
    group?.group_type === 'dispatch' &&
    group?.status.toLowerCase() === 'pending acceptance' &&
    group?.user_id === id
  ) {
    return true;
  }
  // if (
  //   group?.group_type === 'assignment' &&
  //   group?.status.toLowerCase() === 'assigned' &&
  //   group?.user_id === id
  // ) {
  //   return true;
  // }
  return false;
};

// todo to check if all conditions are correct
export const getIssueMode = issue => {
  const {
    current_user: { id }
  } = getUserData();
  if (
    issue?.assignment_type === 'dispatch' &&
    issue?.status.toLowerCase() === 'pending acceptance' &&
    issue?.user_id === id
  ) {
    return true;
  }
  return false;
};

export const logoutV2 = () => {
  clearLocalStorage();
};

export const checkAuth = async () => {
  await getMe();
};
